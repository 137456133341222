/** Fonts imports 
  - Inter
  - Roboto
  - DM Sans
**/
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&family=Inter:wght@400;500;700&family=Roboto:wght@400;500;700&display=swap");

$purple: #51459e;
$cyan: #84e8f4;
$lightsalmon: #fbb39f;
$salmon: #f9896b;
$darksalmon: #f86e49;
$gray: #dfdfdf;
$darkgray: #999a99;
$lightgray: #f2f4f8;
$darkergray: #7c7c7c;
$pearl: #fefef9;
$black: black;
$lightblack: rgb(48, 48, 48);
$danger: #dc3545;
$white: #fff;
$theme-colors: (
  "primary": $salmon,
  "cyan": $cyan,
  "purple": $purple,
  "gray": $gray,
  "lightgray": $lightgray,
  "pearl": $pearl,
  "lightblack": $lightblack,
  "black": $black,
  "darkgray": $darkgray,
  "darkergray": $darkergray,
  "danger": $danger,
  "white": $white,
  "lightsalmon":$lightsalmon,
  "darksalmon":$darksalmon
);
$font-family-sans-serif: "DM Sans", sans-serif;
$breadcrumb-divider: "\25B8";
@import "bootstrap";

h1 {
  font-weight: bold;
}
/* Removes Bootstrap's default button outlines */

.form-control {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
/*
.form-control[type=file]::-webkit-file-upload-button,
.form-control[type=file]::-ms-browse,
.form-control[type=file]::file-selector-button{
  @extend .btn;
}
.btn[disabled] {
  &:hover {
    animation: shake 0.4s;
  }
}
*/
a, .a-like{
  color:$salmon;
  &:visited{
    color:$darksalmon;
  }
  &:hover{
    color:$lightsalmon;
  }
}
:not(.btn-check) + .btn:hover, .btn:first-child:hover{
 &.btn-primary:hover{
  color: $white;
  background-color: $darksalmon;
 }
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn{
  color:#fffa;
}
.btn{
  font-weight: bold;
  color: unset;
  &:focus,
  &:active,
  &:focus-visible,
  &:focus-within {
    outline: none !important;
    box-shadow: none !important;
  }
  &-primary {
    color: $lightgray;
    &:focus {
      color: $pearl;
    }
    &[disabled] {
      color: $white;
    }
    &:hover {
      color: $white;
    }

    
  }
  &-transparent {
    background-color: transparent;
    color: $salmon !important;
    border: 1px solid $salmon !important;
    &:hover {
      color: $white !important;
      background-color: $salmon !important;
    }
    &-purple {
      background-color: transparent;
      color: $purple;
      border: 1px solid $purple;
      &:hover {
        color: $white;
        background-color: $purple;
      }
    }
  }
  &-light {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 50%;
    margin: 3px;
    color: $darkergray;
    border-color: #b8b8b8;
    border: 0;
    transition: none;
    &:hover {
      border-color: #b8b8b8;
      color: #000;
      background-color: #ececec;
      &:active {
        background-color: $white;
        border-bottom: 0;
      }
    }
  }
}
input[type="file"] {
  &::file-selector-button{
    border-radius: 4px;
    margin: 0 3px;
    background-color: $salmon;
    color: $lightgray;
    font-weight: bold;
  }
  &:focus,
  &:focus.within,
  &:active,
  &:hover {
    &::file-selector-button{
      border-radius: 4px;
      margin: 0 3px;
      background-color: $salmon;
      color: $lightgray;
      font-weight: bold;
    }
  }
}